//add internship form queries
export const getFaculties = "getFaculties";
export const getFacultiesWithCompanyAccordList =
	"getFacultiesWithCompanyAccord";
export const getFacultiesOfInterestList = "getFacultiesOfInterest";
export const getCompanyUsers = "getAllCompanyUsers";
export const listCompanyUsers = "listAllCompanyUsers";
export const listCompanyUsersForCompany = "listCompanyUsersForCompany";
export const getSpecificProfessor = "getProfessorById";
export const getFacultiesDepartments = "getDepartmentsForFaculties";
export const getFacultiesStudyPrograms = "getStudyProgramsForFaculties";
export const getSpecificInternship = "getInternshipQuery";
export const getAllFacultyProfessors = "getAllFacultyProfessors";
export const getCoTutorProfessors = "getCoTutorProfessors";
export const getProfessors = "getAllProfessors";
export const getAvailableSupervisors = "getAvailableSupervisors";
export const getAllocatedSupervisors = "getAllocatedSupervisors";
export const getAvailablePrograms = "getAvailablePrograms";
export const getSupervisedPrograms = "getSupervisedPrograms";

//add research proposal form queries
export const getSpecificResearchProposal = "getSpecificResearchProposal";

//admin queries
export const getDocuments = "getDocuments";
export const getUsers = "getUsers";
export const getAllCompanies = "getCompanies";
export const getFilterForProfessor = "getProfessorFilters";
export const getCompanyFilters = "getCompanyFilters";

//GDPR queries
export const geStudentGDPRAcceptData = "geStudentGDPRAcceptData";

//role queries
export const getRoles = "getRoles";

//header queries
export const getAllApplicationTypesCount = "getAllApplicationTypesCount";
export const getAllCompanyCount = "getAllCompanyCounters";
export const getStudentsTypesCount = "getStudentsTypesCount";
export const getPracticeStudentsCount = "getPracticeStudentsCount";
export const getDiplomaStudentsCount = "getDiplomaStudentsCount";

//internships queries
export const getInternshipData = "getInternship";
export const getInternshipsFilters = "getInternshipsFilters";
export const getCompanyInternships = "getCompanyInternships";
export const getProfessorInternships = "getProfessorInternships";
export const getCoordinatedInternships = "getCoordinatedProfessorInternships";
export const getCoordinatedInternshipsFilters =
	"getCoordinatedProfessorInternshipsFilters";
export const getFacultiesInternshipsFiltersForProfessor =
	"getFacultyProfessorInternshipsFilters";
export const getDepartmentInternshipsFiltersForProfessor =
	"getDepartmentProfessorInternshipsFilters";
export const getStudyProgramInternshipsFiltersForProfessor =
	"getStudyProgramProfessorInternshipsFilters";
export const getFacultyInternships = "getFacultyInternships";
export const getDepartmentInternships = "getDepartmentInternships";
export const getStudyProgramInternships = "getStudyProgramInternships";
export const getInternshipApplication = "getInternship";
export const getApplicationsData = "getApplicationsData";
export const getPreviousEnrollmentsData = "getPreviousEnrollmentsData";
export const getBachelorStudents = "getAvailableBachelorStudents";
export const getMasterStudents = "getAvailableMasterStudents";
export const updateCompanyInternshipViewCount =
	"updateCompanyInternshipViewCountByInternshipId"; // de investigat
export const updateProfessorInternshipViewCount =
	"updateProfessorInternshipViewCountByInternshipId"; // de investigat
export const getMyResearchOffers = "getMyResearchOffers";
export const getActiveResearchOffers = "getActiveResearchOffers";
export const getResearchApplicants = "getResearchApplicants";
export const getMyResearchOffersFilters = "getMyResearchOffersFilters";
export const getActiveResearchOffersFilters = "getActiveResearchOffersFilters";
export const getResearchApplicantsFilters = "getResearchApplicantsFilters";
export const getResearchApplications = "getResearchApplications";

//validate e-mail
export const validateEmail = "validateEmail";

//profile queries
export const getCompanyUser = "getCompanyUser";
export const getCompanyAccountInfo = "getCompanyAccountInfo";
export const getStudentAccountInfo = "getStudentAccountInfo";
export const getProfessorAccountInfo = "getProfessorAccountInfo";
export const getFacultyDepartments = "getDepartmentsForFaculty";
export const getStudyProgramsInfo = "getStudyPrograms";
export const getCurrentStudentStudyProgramInfo =
	"getCurrentStudentStudyProgram";
export const getProfessor = "getProfessor";
export const getCompany = "getCompany";

//notifications queries
export const getNotifications = "getNotifications";
export const getNotificationsNumber = "getNotificationsNumber";

//practice queries
export const getPracticeHoursForFacultyByEnrollment =
	"getPracticeHoursForFacultyByEnrollmentId";
export const getInternshipEnrollment = "getInternshipEnrollmentById";
export const getPracticeCertificate = "getPracticeCertificateQuery";
export const getPracticeNotebook = "getPracticeNotebookQuery";
export const getPracticeStudentsInfo = "getPracticeStudents";
export const getPracticeStudentsFilter = "getPracticeStudentsFilters";

//report queries
export const getPrograms = "getPrograms";
export const getProfessorProposalReportInfo = "getProfessorProposalReport";
export const getMissingStudentProjectEnrollmentReportInfo =
	"getMissingStudentProjectEnrollmentReport";
export const getProjectAllocationReportInfo = "getProjectAllocationReport";
export const getMissingStudentPracticeInternshipEnrollmentReportInfo =
	"getMissingStudentPracticeInternshipEnrollmentReportInfo";
export const getPracticeInternshipAllocationReportInfo =
	"getPracticeInternshipAllocationReportInfo";
export const getStudentAllocationPerDomainReportInfo =
	"getStudentAllocationPerDomainReportInfo";
export const getPracticeInternshipPerDomainReportInfo =
	"getPracticeInternshipPerDomainReportInfo";
export const getSummerSchoolReportInfo = "getSummerSchoolReport";

//student queries
export const getStudentAppreciations = "getStudentAppreciations";
export const getStudentPersonalData = "getStudentPersonalDataForStudent";
export const getStudentCurrentStudyProgram = "getCurrentStudentStudyProgram";

//students queries
export const getTraineesFilters = "getTraineesFilters";
export const getInterns = "getInterns";
export const getApprovedStudentsFilters = "getApprovedStudentsFilters";
export const getStudents = "getStudents";
export const getStudentsFilters = "getStudentsFilters";

//accords queries
export const getProfessorProfile = "getProfessorProfile";
export const getUniversityAccordsInfo = "getUniversityAccords";
export const getFiltersForAccords = "getFiltersForAccords";

//statistics queries
export const getStatisticsForLandingPage = "getStatisticsForLandingPage";

//companies queries
export const getCompaniesQuery = "getCompanies";

//newsletter queries
export const getNewsletterEmailData = "getNewsletterEmailData";
export const getEmailRecipients = "getEmailRecipients";

//filters
export const companyInternshipsFiltersKey = "companyInternshipsFiltersKey";
export const facultyInternshipsFiltersKey = "facultyInternshipsFiltersKey";
