import { Form, Row, Col } from "antd";
import Layout from "../../Containers/Layout";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import CustomForm from "../../CustomComponents/CustomForm";
import styles from "./Feedback.module.scss";
import { theme } from "../../theme";
import Question from "./Question";
import { useQuery } from "react-query";
import {
	FormTypeEnum,
	QuestionPrerequisiteDTO,
	QuestionTypeEnum,
	StudentYearEnum,
} from "../../Api";
import {
	addAnswer,
	getQuestionsByForm,
} from "../../Requests/feedback-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { AnswerAddDTO } from "../../Api/models/AnswerAddDTO";
import CustomButton from "../../CustomComponents/CustomButton";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useUser } from "../../utils/utilFunctions";

const BachelorStudentsForm = () => {
	const { data: user } = useUser();
	const { t } = i18n;
	const formType = FormTypeEnum.BachelorMasterStudentForm;
	const [confirmationModalVisibility, setConfirmationModalVisibility] =
		useState(false);
	const [redirect, setRedirect] = useState(false);

	const [form] = Form.useForm<{
		[key: string]: string | null;
		q1: string | null;
		q2: string | null;
		q3: string | null;
		q4: string | null;
		q5: string | null;
		q6: string | null;
		q7: string | null;
		q8: string | null;
		q9: string | null;
		q10: string | null;
		q11: string | null;
		q12: string | null;
		q13: string | null;
		q14: string | null;
		q15: string | null;
		q16: string | null;
		q17: string | null;
		q18: string | null;
		q19: string | null;
		q20: string | null;
		q21: string | null;
		q22: string | null;
		q23: string | null;
		q24: string | null;
		q25: string | null;
		q26: string | null;
		q27: string | null;
		q28: string | null;
		q29: string | null;
		q30: string | null;
		q31: string | null;
		q32: string | null;
		q33: string | null;
		q34: string | null;
		q35: string | null;
		q36: string | null;
		q37: string | null;
		q38: string | null;
		q39: string | null;
		q40: string | null;
		q41: string | null;
		q42: string | null;
		q43: string | null;
		q44: string | null;
		q45: string | null;
		q46: string | null;
		q47: string | null;
		q48: string | null;
		q49: string | null;
		q50: string | null;
		q51: string | null;
		q52: string | null;
		q53: string | null;
		q54: string | null;
		q55: string | null;
		q56: string | null;
		q57: string | null;
		q58: string | null;
		q59: string | null;
		q60: string | null;
		q61: string | null;
		q62: string | null;
		q63: string | null;
	}>();

	const openFetchError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Datele nu au putut fi aduse din server!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Raspunsurile nu au putut fi salvate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveSuccess = (_message: any) => {
		openNotification(
			"Info!",
			"Raspunsurile au fost salvate!",
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const openMissingAnswers = (_message: any) => {
		openNotification(
			"Eroare!",
			"Nu toate intrebarile au fost completate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data } = useQuery(
		["getQuestions", formType],
		() => {
			return getQuestionsByForm(formType);
		},
		{
			onError: openFetchError,
		}
	);
	useEffect(() => {}, [Form.useWatch("q1", form)]);

	const validateForm = async () => {
		try {
			await form.validateFields();
			handleSave();
		} catch (error) {
			openMissingAnswers(error);
		}
	};

	const handleSave = () => {
		const state = form.getFieldsValue();
		const answerAddDTOArray: AnswerAddDTO[] = [];

		data?.forEach((question) => {
			const questionName = question.name!;
			const questionId = question.questionId;

			if (state.hasOwnProperty(questionName)) {
				const answerAddDTO: AnswerAddDTO = {
					questionId,
					answers: [state[questionName]!.toString() ?? ""],
				};
				answerAddDTOArray.push(answerAddDTO);
			}
		});

		addAnswer(answerAddDTOArray, formType)
			.then(() => {
				openSaveSuccess("");
				setRedirect(true);
			})
			.catch(openSaveError);
	};

	const showQuestion = (
		prerequisites: QuestionPrerequisiteDTO[] | undefined,
		qNumber: number | undefined
	): boolean => {
		if (prerequisites === undefined) {
			return true;
		}
		const state = form.getFieldsValue();

		let show = true;
		if (user === undefined) {
			return false;
		}
		prerequisites.forEach((p) => {
			if (
				(state[p.name as keyof typeof state] !== p.answer &&
					state[p.name as keyof typeof state] !== undefined) ||
				(user.year != StudentYearEnum.L1 &&
					user.year != StudentYearEnum.L2 &&
					qNumber === 2)
			) {
				show = false;
			}
		});

		return show;
	};

	const validationType = (questionType: QuestionTypeEnum): any => {
		switch (questionType) {
			case QuestionTypeEnum.LikertAccord:
				return "number";
			case QuestionTypeEnum.LikertSatisfaction:
				return "number";
			case QuestionTypeEnum.MultipleChoice:
				return "array";
			default:
				return "string";
		}
	};

	if (redirect) {
		return <Redirect to="/" />;
	} else {
		return (
			<Layout>
				<div className={styles.container}>
					<CustomForm
						form={form}
						layout={theme.layout}
						action="/domenii"
						boxshadow={"none"}
					>
						<div className={styles.firstLine}>
							<Row gutter={8}>
								{data?.map((question) => {
									return (
										showQuestion(
											question.prerequisites !== null
												? question.prerequisites
												: undefined,
											question.orderNumber
										) && (
											<Col key={question.name} span={24}>
												{question.orderNumber == 3 ? (
													<label className={styles.categoryLabel}>
														Calitatea desfășurării programului de studii
													</label>
												) : (
													""
												)}
												{question.orderNumber == 26 ? (
													<label className={styles.categoryLabel}>
														Calitatea serviciilor oferite de universitate
													</label>
												) : (
													""
												)}
												<Form.Item
													name={question.name!}
													initialValue={question.initialValue!}
													rules={[
														{
															type: validationType(
																question.questionType ??
																	QuestionTypeEnum.Default
															),
															required: true,
															message: "Raspuns obligatoriu!",
															whitespace: true,
														},
													]}
												>
													<Question
														props={{
															question:
																question.orderNumber! + "." + question.text!,
															answers: question.answers!,
															type: question.questionType!,
														}}
													/>
												</Form.Item>
											</Col>
										)
									);
								})}
							</Row>
						</div>
					</CustomForm>
					<div className={styles.saveBtn}>
						<CustomButton onClick={validateForm}>Salveaza</CustomButton>
					</div>
				</div>
				<ConfirmationModal
					modalText={t("feedback.submit")}
					handleFunction={() => handleSave()}
					modalVisibility={confirmationModalVisibility}
					title=""
					changeModalVisibility={() => setConfirmationModalVisibility(false)}
					spinning={false}
				/>
			</Layout>
		);
	}
};

export default BachelorStudentsForm;
