import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { InternshipDTO, InternshipEngagementType } from "../../../../Api";
import moment from "moment/moment";
import { isUndefined } from "lodash";

export const AddInternshipFormInit = (defaultValues?: InternshipDTO) => {
	const getDefaultValues = (initialData?: any) => {
		let defaultValues: any = {
			internshipName: "",
			companyDepartment: "",
			description: "",
			shortDescription: "",
			internshipLink: "",
			category: "",
			engagementType: "",
			totalPracticeHours: 360,
			initialAvailablePositions: 1,
			period: 6,
			skills: [],
			startingDate: moment(),
			isPaid: false,
			isViewOnly: false,
			availabilityAd: [moment(), moment().add(moment.duration(30, "days"))],
			departments: [],
			studyPrograms: [],
			years: [],
			tutor: "",
			coordinator: "",
			coTutorIds: [],
			coordinatingProfessor: "",
			internshipCompetences: [{}],
			facultySingleSelect: false,
		};

		if (!isUndefined(initialData)) {
			defaultValues = {
				...defaultValues,
				...initialData,
			};
		}

		return defaultValues;
	};

	defaultValues = getDefaultValues(defaultValues);
	const { t } = useTranslation();
	let schema = yup.object().shape({
		internshipName: yup
			.string()
			.required(
				t("internships.addInternshipForm.requiredField", {
					field: t("internships.addInternshipForm.internshipTitle"),
				})
			)
			.max(250, t("internships.addInternshipForm.nameLengthError")),
		category: yup
			.string()
			.max(250, t("internships.addInternshipForm.keywordsLengthError")),
		engagementType: yup
			.string()
			.oneOf<InternshipEngagementType>([
				InternshipEngagementType.BachelorsInternship,
				InternshipEngagementType.MastersInternship,
				InternshipEngagementType.ResearchInternship,
				InternshipEngagementType.Internship,
				InternshipEngagementType.Scholarship,
				InternshipEngagementType.Job,
				InternshipEngagementType.SummerSchool,
				InternshipEngagementType.Event,
			])
			.required(
				t("internships.addInternshipForm.requiredField", {
					field: t("internships.addInternshipForm.type"),
				})
			),
		totalPracticeHours: yup
			.number()
			.required(
				t("internships.addInternshipForm.requiredField", {
					field: t("internships.addInternshipForm.totalPracticeHours"),
				})
			)
			.typeError(t("internships.addInternshipForm.requiredNumber")),
		companyDepartment: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.companyDepartment"),
			})
		),
		initialAvailablePositions: yup
			.number()
			.required(
				t("internships.addInternshipForm.requiredField", {
					field: t("internships.addInternshipForm.freePosts"),
				})
			)
			.typeError(t("internships.addInternshipForm.requiredNumber")),
		description: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.descriptionField"),
			})
		),
		shortDescription: yup
			.string()
			.max(
				1000,
				t("internships.addInternshipForm.shortDescriptionLengthError")
			),
		internshipLink: yup
			.string()
			.max(1000, t("internships.addInternshipForm.internshipLinkLengthError")),
		coordinatingProfessor: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.coordinatingProfessor"),
			})
		),
		tutor: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.companyTutor"),
			})
		),
		coordinator: yup.string(),
		studyPrograms: yup.string(),
		years: yup.string(),
		coTutorIds: yup.string(),
		skills: yup.string(),
		location: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.location"),
			})
		),
		startingDate: yup.string().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.addInternshipForm.internshipStartDate"),
			})
		),
		period: yup
			.number()
			.required(
				t("internships.addInternshipForm.requiredField", {
					field: t("internships.addInternshipForm.internshipPeriodPlaceholder"),
				})
			)
			.typeError(t("internships.addInternshipForm.requiredNumber")),
		availabilityYear: yup.number().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.availabilityAd"),
			})
		),
		availabilityAd: yup.array().required(
			t("internships.addInternshipForm.requiredField", {
				field: t("internships.availabilityAd"),
			})
		),
	});

	const yupSync = {
		async validator({ field }: any, value: any) {
			await schema.validateSyncAt(field, { [field]: value });
		},
	};

	return { defaultValues, yupSync };
};
