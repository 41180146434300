import { CompanyUsersSelectProps } from "./CompanyUsersSelect.types";
import { Form, Select } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import { useQuery } from "react-query";
import { getCompanyUsers } from "../../../../../../utils/reactQueriesConstants";
import { getAllCompanyUsers } from "../../../../../../Requests/company-users-requests";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

const { Option } = Select;

export const CompanyUsersSelect = (props: CompanyUsersSelectProps) => {
	const { t } = useTranslation();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: companyUsers } = useQuery(
		[getCompanyUsers, props.isCompany],
		async () => (props.isCompany ? getAllCompanyUsers("", 1, 100) : null),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<Form.Item
			required
			label={t("internships.addInternshipForm.companyTutor") + ":"}
			name="tutor"
			style={{ marginTop: "-10px", marginBottom: "10px" }}
			rules={[props.yupSync]}
		>
			<Select
				className={styles.antSelect}
				allowClear
				disabled={props.disabled}
				style={{ width: "100%", textAlign: "left" }}
				placeholder={t("internships.addInternshipForm.companyTutor")}
				notFoundContent={t("internships.addInternshipForm.notFoundContent")}
			>
				{companyUsers?.data?.map((user) => (
					<Option key={user.id} value={user.id}>
						{user.name}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};
