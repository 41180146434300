import { getProfessorInternshipById } from "../../../Requests/professor-internship-requests";
import { getCompanyInternshipById } from "../../../Requests/company-internship-requests";

export const getInternship = async (
	isProfessor?: boolean,
	isCompany?: boolean,
	internshipId?: string
) => {
	if (!internshipId) {
		return null;
	}

	if (isProfessor) {
		return await getProfessorInternshipById(internshipId);
	}

	if (isCompany) {
		return await getCompanyInternshipById(internshipId);
	}

	return null;
};
