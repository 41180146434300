import { t } from "i18next";
import { Filter } from "../Api";

export const parseFilters = (filters: Filter[]) => {
	if (!filters) return;

	const res = filters.map((filter) => {
		const options: { key: string; value?: string | null }[] = [];

		filter.children?.forEach((option) => {
			const keys = option.key?.split("@");
			if (keys?.length)
				options.push({
					key: keys[1],
					value: [
						"StudyProgram",
						"UniversityYear",
						"Department",
						"Faculty",
						"Location",
					].includes(filter.key!)
						? option.title
						: t("filtering." + option.title),
				});
		});

		return {
			key: filter.key,
			title: t("" + filter.title),
			options: options,
		};
	});

	return res;
};
