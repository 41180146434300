import { PracticeConventionApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPracticeConventionApiFactory = () =>
	new PracticeConventionApi(getAuthConfiguration());

export const changeSupervisionStatus = (id: string, supervise: boolean) => {
	return AuthPracticeConventionApiFactory().apiPracticeConventionChangeSupervisionStatusIdPost(
		{ id, supervise }
	);
};

export const validatePracticeConvention = (id: string) => {
	return AuthPracticeConventionApiFactory().apiPracticeConventionValidateIdPut({
		id,
	});
};

export const downloadPracticeConvention = (enrollmentId: string) => {
	return AuthPracticeConventionApiFactory().apiPracticeConventionDownloadConventionEnrollmentIdGet(
		{ enrollmentId }
	);
};
