import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
interface QueryFiltersProps {
	initialValues?: { [key: string]: string[] | string };
}

function useQueryFilters(props: QueryFiltersProps) {
	const { initialValues } = props;
	const { search, pathname } = useLocation();
	const router = useHistory();

	const [filters, setFilters] = useState<{
		[key: string]: string[] | string;
	}>({});

	useEffect(() => {
		if (!search && initialValues) {
			let initial: { [key: string]: string } = {};
			for (const [key, value] of Object.entries(initialValues)) {
				if (value) {
					initial[key] = JSON.stringify(value);
				}
			}

			const newParams = new URLSearchParams(initial);
			router.push(pathname + (newParams ? "?" + newParams : ""));
		}
	}, [initialValues, pathname, router, search]);

	useEffect(() => {
		const url = new URLSearchParams(search);
		if (!url) return;

		let initial: { [key: string]: string[] | string } = {};
		for (const [key, value] of url) {
			initial[key] = JSON.parse(value);
		}

		setFilters(initial);
	}, [search]);

	const update = useCallback(
		(newValues: { [key: string]: string[] | string }, resetOld?: boolean) => {
			if (!newValues) return;

			let urlValues: { [key: string]: string } = {};

			for (const [key, value] of Object.entries(newValues)) {
				if (value) {
					urlValues[key] = key === "tab" ? value[0] : JSON.stringify(value);
				}
			}
			if (!resetOld)
				for (const [key, value] of Object.entries(filters)) {
					if (!urlValues.hasOwnProperty(key)) {
						urlValues[key] = JSON.stringify(value);
					}
				}

			const newParams = new URLSearchParams(urlValues);
			router.push(pathname + (newParams ? "?" + newParams : ""));
		},
		[filters, pathname, router]
	);

	const clear = () => router.push(pathname);

	return { update, clear, filters };
}

export default useQueryFilters;
