import { Row, Radio, Space, RadioChangeEvent, Checkbox, Input } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import styles from "./Feedback.module.scss";
import { QuestionTypeEnum } from "../../Api/models/QuestionTypeEnum";

const likertSatisfcationAnswers = [
	"1 (Nesatisfăcător)",
	"2 (Satisfăcător)",
	"3 (Bine)",
	"4 (Foarte bine)",
	"5 (Excelent)",
	"Nu se aplica",
];
const likertAccordAnswers = [
	"1 (Total dezacord)",
	"2 (Dezacord)",
	"3 (Nici acord, nici dezacord)",
	"4 (Acord)",
	"5 (Total acord)",
	"Nu se aplica",
];
const YesNoAnswers = ["Da", "Nu", "Nu se aplica"];

type CustomFormItemProps = {
	value?: number | number[] | string;
	onChange?: (value: number | number[] | string) => void;
	props: { question: string; type: QuestionTypeEnum; answers?: string[] };
};

const Question: React.FC<CustomFormItemProps> = ({
	value,
	onChange,
	props,
}) => {
	const onChangeSingleChoice = (e: RadioChangeEvent) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	const onChangeMultipleChoice = (checkedValues: CheckboxValueType[]) => {
		if (typeof onChange === "function") {
			onChange(checkedValues as number[]);
		}
	};

	const onChangeText = (e: any) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	return (
		<div className={styles.questionBorder}>
			<Row>
				<p
					style={{ marginBottom: "8px", fontSize: "1.5em", textAlign: "left" }}
				>
					<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
					{props.question}
				</p>
			</Row>

			{/* Single choice question */}
			{props.type === QuestionTypeEnum.SingleChoice && (
				<Row>
					<Radio.Group value={value} onChange={onChangeSingleChoice}>
						<Space direction="vertical" align="start">
							{props.answers?.map((answer, index) => {
								return (
									<Radio value={answer} key={answer}>
										{answer}
									</Radio>
								);
							})}
						</Space>
					</Radio.Group>
				</Row>
			)}

			{/* Multiple choice question */}
			{props.type === QuestionTypeEnum.MultipleChoice && (
				<Row>
					<Checkbox.Group
						defaultValue={value as number[]}
						onChange={onChangeMultipleChoice}
					>
						<Space direction="vertical" align="start">
							{props.answers?.map((answer, index) => {
								return (
									<Checkbox value={answer} key={answer}>
										{answer}
									</Checkbox>
								);
							})}
						</Space>
					</Checkbox.Group>
				</Row>
			)}

			{/* LikertAccord question */}
			{props.type === QuestionTypeEnum.LikertAccord && (
				<Row>
					<Radio.Group
						value={value}
						onChange={onChangeSingleChoice}
						buttonStyle="solid"
					>
						{likertAccordAnswers.map((answer, index) => {
							return (
								<Radio.Button
									className={styles.radioButtons}
									style={{ margin: "3px" }}
									value={index + 1}
									key={answer}
								>
									{answer}
								</Radio.Button>
							);
						})}
					</Radio.Group>
				</Row>
			)}

			{/* LikertSatisfaction question */}
			{props.type === QuestionTypeEnum.LikertSatisfaction && (
				<Row>
					<Radio.Group
						value={value}
						onChange={onChangeSingleChoice}
						buttonStyle="solid"
					>
						{likertSatisfcationAnswers.map((answer, index) => {
							return (
								<Radio.Button
									className={styles.radioButtons}
									style={{ margin: "3px" }}
									value={index + 1}
									key={answer}
								>
									{answer}
								</Radio.Button>
							);
						})}
					</Radio.Group>
				</Row>
			)}

			{/* YesNo question */}
			{props.type === QuestionTypeEnum.YesNo && (
				<Row>
					<Radio.Group
						value={value}
						onChange={onChangeSingleChoice}
						buttonStyle="solid"
					>
						{YesNoAnswers.map((answer, index) => {
							return (
								<Radio.Button
									style={{ margin: "3px" }}
									className={styles.radioButtons}
									value={answer}
									key={answer}
								>
									{answer}
								</Radio.Button>
							);
						})}
					</Radio.Group>
				</Row>
			)}

			{/* OpenText question */}
			{props.type === QuestionTypeEnum.OpenText && (
				<Row>
					<Input.TextArea
						defaultValue={value as string}
						autoSize={{ minRows: 2, maxRows: 8 }}
						onChange={onChangeText}
					/>
				</Row>
			)}
		</div>
	);
};

export default Question;
